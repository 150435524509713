'use strict'

const _ = require('lodash')
// const loggingUtils = require('loggingUtils')
const coreUtils = require('santa-core-utils')
const galleriesMobileTypeFixer = require('../plugins/galleriesMobileTypeFixer')
const pageTopFixer = require('../plugins/pageTopFixer')
const masterPageFixer = require('../plugins/masterPageFixer')
const customSiteMenuFixer = require('../plugins/customSiteMenuFixer')
const multipleMenusFixer = require('../plugins/multipleMenusFixer')
const skinFixer = require('../plugins/skinFixer')
const stylesFixer = require('../plugins/stylesFixer')
const bgImageStripDataFixer = require('../plugins/bgImageStripDataFixer')
const compFixer = require('../plugins/compFixer')
const galleryFixer = require('../plugins/galleryFixer')
const behaviorsFixer = require('../plugins/behaviorsFixer')
const connectionsDataFixer = require('../plugins/connectionsDataFixer')
const fiveGridLineLayoutFixer = require('../plugins/fiveGridLineLayoutFixer')
const toPageAnchorsFixer = require('../plugins/toPageAnchorsFixer')
const wrongAnchorsFixer = require('../plugins/wrongAnchorsFixer')
const addMissingAnchorsOfMasterPage = require('../plugins/addMissingAnchorsOfMasterPage')
const sitePagesFixer = require('../plugins/sitePagesFixer')
const linkRefDataFixer = require('../plugins/linkRefDataFixer')
const fromDocumentToThemeData = require('../plugins/fromDocumentToThemeData')
const tpaGluedWidgetDataFixer = require('../plugins/tpaGluedWidgetDataFixer')
const tpaAddChatAppDataFixer = require('../plugins/tpaAddChatAppDataFixer')
const tpaProGalleryBackgroundDataFixer = require('../plugins/tpaProGalleryBackgroundDataFixer')
const compsWithImagesDataFixer = require('../plugins/compsWithImagesDataFixer')
const appPartCustomizationsFixer = require('../plugins/appPartCustomizationsFixer')
const backgroundMediaConverter = require('../plugins/backgroundMediaConverter')
const backgroundMediaUndefinedFixer = require('../plugins/backgroundMediaUndefinedFixer')
const backgroundMediaRefDuplicationFixer = require('../plugins/backgroundMediaRefDuplicationFixer')
const stripContainerBgEffectFixer = require('../plugins/stripContainerBgEffectFixer')
const documentMediaFixer = require('../plugins/documentMediaFixer')
const pinterestFollowFixer = require('../plugins/pinterestFollowFixer')
const blogPageMenuFixer = require('../plugins/blogPageMenuFixer')
const appPartMediaInnerCustomizationFormatFixer = require('../plugins/appPartMediaInnerCustomizationFormatFixer')
const appPartReadMoreValueCustomizationFormatFixer = require('../plugins/appPartReadMoreValueCustomizationFormatFixer')
const appPartTagsValueCustomizationFormatFixer = require('../plugins/appPartTagsValueCustomizationFormatFixer')
const appPartDuplicateCustomizationFixer = require('../plugins/appPartDuplicateCustomizationFixer')
const blogCustomFeedPostsPerPageCustomizationFixer = require('../plugins/blogCustomFeedPostsPerPageCustomizationFixer')
const blogDateAndAuthorHiddenCustomizationFixer = require('../plugins/blogDateAndAuthorHiddenCustomizationFixer')
const blogDateAlignmentCustomizationFixer = require('../plugins/blogDateAlignmentCustomizationFixer')
const appPartBrokenButtonStyleFixer = require('../plugins/appPartBrokenButtonStyleFixer')
const blogDateFormatCustomizationFormatFixer = require('../plugins/blogDateFormatCustomizationFormatFixer')
const contactFormMigrationDataFixer = require('../plugins/contactFormMigrationDataFixer')
const pageDataFixer = require('../plugins/pageDataFixer')
const groupFixer = require('../plugins/groupFixer')
const textSecurityFixer = require('../plugins/textSecurityFixer')
const pageUriSeoFixer = require('../plugins/pageUriSeoFixer')
const migrateStripToColumnsContainer = require('../plugins/migrateStripToColumnsContainer')
const tinyMenuSkinBackgroundFixer = require('../plugins/tinyMenuSkinBackgroundFixer')
const tinyMenuLayoutFixer = require('../plugins/tinyMenuLayoutFixer')
const designDataFixer = require('../plugins/designDataFixer')
const homePageLoginDataFixer = require('../plugins/homePageLoginDataFixer')
const characterSetsFixer = require('../plugins/characterSetsFixer')
const popupPropsTempDataFixer = require('../plugins/popupPropsTempDataFixer')
const blogSelectionSharerCustomizationsFixer = require('../plugins/blogSelectionSharerCustomizationsFixer')
const quickActionBarDataFixer = require('../plugins/quickActionBarDataFixer')
const appPartMobileFeedMediaDateHiddenFixer = require('../plugins/appPartMobileFeedMediaDateHiddenFixer')
const originalImageDataRefFixer = require('../plugins/originalImageDataRefFixer')
const tpaWixForumDataFixer = require('../plugins/tpaWixForumDataFixer')
const filterEffectPropertyFixer = require('../plugins/filterEffectPropertyFixer')
const tinyMenuSkinFixer = require('../plugins/tinyMenuSkinFixer')
const svgShapeToVectorImageFixer = require('../plugins/svgShapeToVectorImageFixer')
const artAndShapeToVectorImageFixer = require('../plugins/artAndShapeToVectorImageFixer')
const migrateIframesToSemiNativeComponents = require('../plugins/migrateIframesToSemiNativeComponents')
const migrateGridDataSource = require('../plugins/migrateGridDataSource')
const unforkDesignData = require('../plugins/unforkDesignData')
const stripColumnsContainerNoDesignFixer = require('../plugins/stripColumnsContainerNoDesignFixer')
const hoverBoxPropertyDataFixer = require('../plugins/hoverBoxPropertyDataFixer')

const plugins = _.compact([
    galleriesMobileTypeFixer,
    masterPageFixer,
    backgroundMediaConverter,
    backgroundMediaUndefinedFixer,
    backgroundMediaRefDuplicationFixer,
    pageDataFixer,
    customSiteMenuFixer,
    multipleMenusFixer,
    skinFixer,
    stylesFixer,
    bgImageStripDataFixer,
    compFixer,
    galleryFixer,
    behaviorsFixer,
    connectionsDataFixer,
    quickActionBarDataFixer,
    fiveGridLineLayoutFixer,
    toPageAnchorsFixer,
    wrongAnchorsFixer,
    addMissingAnchorsOfMasterPage,
    sitePagesFixer,
    linkRefDataFixer,
    fromDocumentToThemeData,
    pageTopFixer,
    tpaGluedWidgetDataFixer,
    tpaAddChatAppDataFixer,
    tpaProGalleryBackgroundDataFixer,
    compsWithImagesDataFixer,
    appPartCustomizationsFixer,
    designDataFixer,
    stripContainerBgEffectFixer,
    documentMediaFixer,
    pinterestFollowFixer,
    blogPageMenuFixer,
    appPartMediaInnerCustomizationFormatFixer,
    appPartReadMoreValueCustomizationFormatFixer,
    appPartTagsValueCustomizationFormatFixer,
    blogCustomFeedPostsPerPageCustomizationFixer,
    blogDateAndAuthorHiddenCustomizationFixer,
    blogDateAlignmentCustomizationFixer,
    appPartDuplicateCustomizationFixer,
    appPartBrokenButtonStyleFixer,
    blogDateFormatCustomizationFormatFixer,
    contactFormMigrationDataFixer,
    pageDataFixer,
    groupFixer,
    textSecurityFixer,
    pageUriSeoFixer,
    migrateStripToColumnsContainer,
    tinyMenuSkinBackgroundFixer,
    tinyMenuLayoutFixer,
    homePageLoginDataFixer,
    popupPropsTempDataFixer,
    characterSetsFixer,
    blogSelectionSharerCustomizationsFixer,
    appPartMobileFeedMediaDateHiddenFixer,
    originalImageDataRefFixer,
    tpaWixForumDataFixer,
    filterEffectPropertyFixer,
    tinyMenuSkinFixer,
    svgShapeToVectorImageFixer,
    artAndShapeToVectorImageFixer,
    migrateIframesToSemiNativeComponents,
    migrateGridDataSource,
    unforkDesignData,
    stripColumnsContainerNoDesignFixer,
    hoverBoxPropertyDataFixer
])

Object.freeze(plugins)

const specs = [
    'merge_clipart_and_image',
    'sv_contactFormTemplatesMigration',
    'sv_contactFormFinalMigration',
    'sv_contactFormFinalMigrationEditor',
    'sv_fixedMobileHeader',
    'sv_migrateTpaToSemiNative',
    'se_vector_image',
    'se_proGalleryBGDataFixer',
    'sv_tpaAddPublicAPI',
    'sv_tpaSectionsMobileFSDataFixer'
]

module.exports = function fixPageData({pageJson, urlFormatModel, isViewerMode, clientSpecMap, quickActionsMenuEnabled, experiments, pageIdsArray}) {
    const magicObject = {}
    magicObject.isSlash = _.matchesProperty('format', coreUtils.siteConstants.URL_FORMATS.SLASH)(urlFormatModel)
    magicObject.pageIdToResolvedUriSEO = _.get(urlFormatModel, 'pageIdToResolvedUriSEO', {})
    magicObject.clientSpecMap = clientSpecMap
    magicObject.quickActionsMenuEnabled = quickActionsMenuEnabled
    magicObject.isExperimentOpen = spec => _.includes(_.intersection(specs, experiments), spec)
    magicObject.isViewerMode = isViewerMode
    const data = pageJson.data || {}
    data.document_data = data.document_data || {}
    data.theme_data = data.theme_data || {}
    data.component_properties = data.component_properties || {}
    data.mobile_hints = data.mobile_hints || {}
    pageJson.data = data
    _.forEach(plugins, plugin => {
        plugin.exec(pageJson, pageIdsArray, null, null, null, null, null, magicObject)
    })
    return pageJson
}
